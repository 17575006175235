import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

export function i18nextConfig(translationStrings) {
  const strings = translationStrings || []
  const language = process.env.GATSBY_BUILD_LANGUAGE
  const additionalLanguages = process.env.GATSBY_ADDITIONAL_LANGUAGES
    ? process.env.GATSBY_ADDITIONAL_LANGUAGES.split(",")
    : ["en"]
  const supportedLngs = [...new Set([language, ...additionalLanguages])]
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      fallbackLng: {
        default: [language]
      },
      load: "all",
      keySeparator: false,
      //debug: process.env.NODE_ENV === "development",
      debug: false,
      returnObjects: true,
      ns: ["translations"],
      defaultNS: "translations",
      supportedLngs: supportedLngs,
      resources: {
        en: {
          translations: JSON.parse(
            `{${strings.reduce((acc, string) => {
              if (string.translation["en"] !== null) {
                acc.push(`"${string.key}": "${string.translation["en"]}"`)
              }
              return acc
            }, [])}}`
          )
        }
      },
      interpolation: {
        escapeValue: false
      }
    })
}
