export const getGenderAttributes = (genderAttributes, gender, widgets) => {
  if (!genderAttributes) return {}

  const fitGuide = (widgets || []).find((wid) => wid._type === "specifications")

  const maleAttributes = {
    active: genderAttributes.maleActive,
    images: genderAttributes.maleImages,
    description: genderAttributes.maleDescription,
    video: genderAttributes.maleVideo,
    sizeGuide: genderAttributes.maleSizeGuide,
    fitGuide,
    pathPrefix: "dudes"
  }

  const femaleAttributes = {
    active: genderAttributes.femaleActive,
    images: genderAttributes.femaleImages,
    description: genderAttributes.femaleDescription,
    video: genderAttributes.femaleVideo,
    sizeGuide: genderAttributes.femaleSizeGuide,
    fitGuide,
    pathPrefix: "ladies"
  }

  const unisexAttributes = {
    active: genderAttributes.unisexActive,
    images: genderAttributes.unisexImages,
    description: genderAttributes.unisexDescription,
    video: genderAttributes.unisexVideo,
    sizeGuide: genderAttributes.unisexSizeGuide,
    fitGuide,
    pathPrefix: null
  }

  if (gender === "ladies") return femaleAttributes
  if (gender === "dudes") return maleAttributes
  if (gender === "unisex") return unisexAttributes
  if (genderAttributes.maleUseAsDefault) return maleAttributes
  if (genderAttributes.femaleUseAsDefault) return femaleAttributes
  if (genderAttributes.unisexUseAsDefault) return unisexAttributes
  return {}
}

export const getActiveGender = (genderAttributes) => {
  let gender = "unisex"
  if (genderAttributes?.maleActive && !genderAttributes?.femaleActive)
    gender = "dudes"
  if (genderAttributes?.femaleActive && !genderAttributes?.maleActive)
    gender = "ladies"
  return getGenderAttributes(genderAttributes, gender)
}
