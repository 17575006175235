import Dinero from "dinero.js"
import createDispatcher from "./createDispatcher"
import { parsePhoneNumberFromString } from "libphonenumber-js/min"
import { sha256 } from "js-sha256"

const dispatcher = createDispatcher("google")

const createItem = ({
  name,
  id,
  price,
  category,
  listName,
  quantity,
  index,
  currency,
  discount,
  positionInList,
  gender,
  itemVariant,
  itemListName
}) => {
  return {
    item_name: name,
    item_id: id,
    google_business_vertical: "retail",
    price: price
      ? Dinero({
          amount: price,
          currency: currency
        }).toUnit()
      : "",
    item_brand: "Cest Normal",
    item_category: category,
    ...(gender && { item_category2: gender }),
    discount: discount || "",
    ...(listName ? { list_name: listName } : {}),
    ...(index || index === 0 ? { index } : {}),
    ...(quantity || quantity === 0 ? { quantity } : {}),
    ...(positionInList && { positionInList }),
    ...(itemVariant && { item_variant: itemVariant }),
    ...(itemListName && { item_list_name: itemListName })
  }
}

const getGenderFromAttributes = (genderAttributes) => {
  if (genderAttributes.maleActive && !genderAttributes.femaleActive)
    return "male"
  if (genderAttributes.femaleActive && !genderAttributes.maleActive)
    return "female"
  return "unisex"
}

const getGender = (gender) => {
  if (gender?.dudes && !gender?.ladies) return "male"
  if (gender?.ladies && !gender?.dudes) return "female"
  return "unisex"
}

const getProductDiscount = (cart, productId) =>
  cart.discounts.productDiscounts.find((d) => d.id === productId)
    ?.discountAmount[cart.store.currencyUnit]

export const addToCart = ({
  productVariantId,
  quantity,
  category,
  cartResponse,
  currentStore
}) => {
  const dataLayer = dispatcher(window.dataLayer)
  const addedProduct = cartResponse.cartItems.find(
    (p) => p.id === productVariantId
  )
  const productDiscount = getProductDiscount(cartResponse, addedProduct.id)
  dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      currency: currentStore.currencyUnit,
      value: Dinero({
        amount: cartResponse.totalPriceWithDiscount,
        currency: cartResponse.store.currencyUnit
      }).toUnit(),
      items: [
        createItem({
          name: addedProduct.name,
          id: productVariantId,
          price: addedProduct.price[currentStore.currencyUnit],
          itemVariant: addedProduct?.customerAttribute?.size,
          discount: productDiscount
            ? Dinero({
                amount: productDiscount,
                currencyUnit: currentStore.currencyUnit
              }).toUnit()
            : 0,
          currency: currentStore.currencyUnit,
          category,
          quantity
        })
      ]
    }
  })
}

export const removeFromCart = ({
  productVariantId,
  name,
  quantity,
  category,
  price,
  discount,
  currentStore
}) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      currency: currentStore.currencyUnit,
      items: [
        createItem({
          name,
          id: productVariantId,
          currency: currentStore.currencyUnit,
          price: price[currentStore.currencyUnit],
          category,
          quantity,
          discount: discount
            ? Dinero({
                amount: discount[currentStore.currencyUnit],
                currencyUnit: currentStore.currencyUnit
              }).toUnit()
            : 0
        })
      ]
    }
  })
}

export const viewProduct = ({
  sanityProductVariant,
  sanityProduct,
  listName,
  prices,
  index,
  discount,
  currentStore
}) => {
  const dataLayer = dispatcher(window.dataLayer)
  const category = sanityProduct?.category?.displayName?.en ?? ""
  const gender = getGenderFromAttributes(sanityProduct?.genderAttributes)
  const price = prices.find(
    (p) => p.currencyUnit === currentStore.currencyUnit
  )?.amount

  const discountAmount = discount?.find(
    (p) => p.currencyUnit === currentStore.currencyUnit
  )

  const items = sanityProductVariant
    ? [
        createItem({
          name: sanityProductVariant.title,
          id: sanityProductVariant._id,
          currency: currentStore.currencyUnit,
          category,
          gender,
          itemVariant: sanityProductVariant?.color,
          listName,
          price,
          index,
          discount: discountAmount ? discountAmount.amount : 0
        })
      ]
    : sanityProduct.variants.map((p) =>
        createItem({
          name: p.title,
          id: p._id,
          category,
          listName,
          gender,
          price,
          index,
          discount: discountAmount
            ? Dinero({
                amount: discountAmount.amount,
                currencyUnit: currentStore.currencyUnit
              }).toUnit()
            : 0
        })
      )

  dataLayer.push({
    event: "view_item",
    ecommerce: {
      currency: currentStore.currencyUnit,
      value: Dinero({
        amount: price,
        currency: currentStore.currencyUnit
      }).toUnit(),
      items: items
    }
  })
}

export const viewCart = ({ cart }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({
    event: "view_cart",
    ecommerce: {
      value: Dinero({
        amount: cart.totalPriceWithDiscount,
        currency: cart.store.currencyUnit
      }).toUnit(),
      ...(cart?.cartItems?.find((item) => item.type === "shippingOption")
        ? {
            shipping: Dinero({
              amount: cart?.cartItems?.find(
                (item) => item.type === "shippingOption"
              )?.price[cart.store.currencyUnit],
              currency: cart.store.currencyUnit
            }).toUnit()
          }
        : {}),
      currency: cart.store.currencyUnit,
      items: cart?.cartItems
        ?.filter((item) => item.type !== "shippingOption")
        .map((i) => {
          const productDiscount = getProductDiscount(cart, i.id)
          return createItem({
            name: i.attribute.displayName.en,
            id: i.id,
            category: i.category,
            price: i.price[cart.store.currencyUnit],
            discount: productDiscount
              ? Dinero({
                  amount: productDiscount,
                  currency: cart.store.currencyUnit
                }).toUnit()
              : 0,
            quantity: i.quantity,
            currency: cart.store.currencyUnit
          })
        })
    }
  })
}

export const viewCollection = ({ products, currentStore, listName }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      currency: currentStore.currencyUnit,
      items: products?.map((p, i) => {
        const variant = p.variants[0]
        const { children } = variant
        const { price, discount } = children[0] || {}
        const discountAmount = discount?.find(
          (p) => p.currencyUnit === currentStore.currencyUnit
        )
        return createItem({
          name: p.displayName.en,
          id: p._id,
          itemListName: listName,
          category: p.category.title,
          currency: currentStore.currencyUnit,
          price: price?.find(
            (p) => p.currencyUnit === currentStore.currencyUnit
          ).amount,
          index: i + 1,
          itemVariant: variant?.color,
          gender: getGender(p.gender),
          discount: discountAmount
            ? Dinero({
                amount: discountAmount.amount,
                currency: currentStore.currencyUnit
              }).toUnit()
            : null
        })
      })
    }
  })
}

export const purchase = ({ order, cart, email, discountCode }) => {
  const dataLayer = dispatcher(window.dataLayer)
  const purchaseEvent = toPurchaseEvent(order, cart, email)
  const purchaseEventWithDiscount = discountCode
    ? { ...purchaseEvent, coupon: discountCode ?? "" }
    : purchaseEvent
  dataLayer.push({
    event: "purchase",
    ecommerce: {
      ...purchaseEventWithDiscount
    }
  })
}

export const addShipping = ({ cart, shippingOption, discountCode }) => {
  const dataLayer = dispatcher(window.dataLayer)
  if (shippingOption)
    dataLayer.push({
      event: "add_shipping_info",
      ecommerce: {
        currency: cart.store.currencyUnit,
        value: Dinero({
          amount: cart.totalPriceWithDiscount,
          currency: cart.store.currencyUnit
        }).toUnit(),
        coupon: discountCode ?? "",
        shipping_tier: shippingOption.displayName.en,
        items: cart?.cartItems
          ?.filter((item) => item.type !== "shippingOption")
          .map((i) => {
            const productDiscount = getProductDiscount(cart, i.id)
            return createItem({
              name: i.attribute.displayName.en,
              id: i.id,
              category: i.category,
              itemVariant: i?.customerAttribute.size,
              price: i.price[cart.store.currencyUnit],
              discount: productDiscount
                ? Dinero({
                    amount: productDiscount,
                    currency: cart.store.currencyUnit
                  }).toUnit()
                : 0,
              quantity: i.quantity,
              currency: cart.store.currencyUnit
            })
          })
      }
    })
}

const toPurchaseEvent = (order, cart, email) => {
  const physicalOrderlines = order.orderLines.filter(
    (o) => o.type === "physical"
  )
  if (order) {
    return {
      transaction_id: order.id,
      value: Dinero({
        amount: order.orderAmountWithDiscount,
        currency: order.currencyUnit
      }).toUnit(),
      currency: order.currencyUnit,
      tax: Dinero({
        amount: order.orderTaxAmount,
        currency: order.currencyUnit
      }).toUnit(),
      shipping: Dinero({
        amount: order.orderLines.find((item) => item.type === "shipment")
          .totalAmountWithDiscount,
        currency: order.store.currencyUnit
      }).toUnit(),
      sha256_email_address: sha256(email.trim()),
      sha256_phone_number: sha256(
        formatPhoneNumber(
          order.billingAddress.phone,
          order.billingAddress.country
        )
      ),
      google_business_vertical: "retail",
      items: physicalOrderlines.map((ol) =>
        createItem({
          id: ol.productId,
          name: ol.name,
          quantity: ol.quantity,
          category: cart.cartItems.find((c) => c.id === ol.productId).category,
          itemVariant: cart.cartItems.find((c) => c.id === ol.productId)
            .customerAttribute.size,
          price: ol.totalAmount,
          discount: Dinero({
            amount: ol.totalAmount - ol.totalAmountWithDiscount,
            currency: order.currencyUnit
          }).toUnit()
        })
      )
    }
  }
}

const formatPhoneNumber = (phone, countryCode) => {
  const phoneNumber = parsePhoneNumberFromString(phone || "", countryCode)
  return phoneNumber ? phoneNumber.number.replace(/^\+/, "") : ""
}

export const initiateCheckout = ({ storedCart }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      currency: storedCart.store.currencyUnit,
      value: Dinero({
        amount: storedCart.totalPriceWithDiscount,
        currency: storedCart.store.currencyUnit
      }).toUnit(),
      items: storedCart.cartItems
        .filter((item) => item.type !== "shippingOption")
        .map((i) => {
          const productDiscount = getProductDiscount(storedCart, i.id)
          return createItem({
            id: i.id,
            name: i.name,
            brand: "Cest normal",
            itemVariant: i?.customerAttribute?.size,
            price: i.price[storedCart.store.currencyUnit],
            discount: productDiscount
              ? Dinero({
                  amount: productDiscount,
                  currency: storedCart.store.currencyUnit
                }).toUnit()
              : 0,
            category: i.category,
            quantity: i.quantity,
            currency: storedCart.store.currencyUnit
          })
        })
    }
  })
}

export const addPaymentInfo = ({ cart, paymentMethod, discountCode }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({
    event: "add_payment_info",
    ecommerce: {
      currency: cart.store.currencyUnit,
      value: Dinero({
        amount: cart.totalPriceWithDiscount,
        currency: cart.store.currencyUnit
      }).toUnit(),
      coupon: discountCode ?? "",
      payment_type: paymentMethod,
      items: cart.cartItems
        .filter((item) => item.type !== "shippingOption")
        .map((i) => {
          return createItem({
            id: i.id,
            name: i.name,
            brand: "Cest Normal",
            price: i.price[cart.store.currencyUnit],
            discount:
              i.discount && Object.keys(i.discount).length > 0
                ? Dinero({
                    amount: i.discount[cart.store.currencyUnit],
                    currency: cart.store.currencyUnit
                  }).toUnit()
                : 0,
            quantity: i.quantity,
            currency: cart.store.currencyUnit
          })
        })
    }
  })
}

export const clickProduct = ({ product, positionInList, currentStore }) => {
  const dataLayer = dispatcher(window.dataLayer)
  const variant = product.variants[0]
  const { children } = variant
  const { price, discount } = children[0] || {}

  const discountAmount = discount?.find(
    (p) => p.currencyUnit === currentStore?.currencyUnit
  )
  dataLayer.push({
    event: "select_item",
    ecommerce: {
      currency: currentStore?.currencyUnit,
      items: [
        createItem({
          id: product._id || product.id,
          name: product.title || product.displayName.en,
          category: product.category.title,
          color: variant?.color,
          gender: product.gender
            ? getGender(product.gender)
            : getGenderFromAttributes(variant?.genderAttributes),
          currency: currentStore?.currencyUnit,
          positionInList,
          price: price.find(
            (p) => p.currencyUnit === currentStore?.currencyUnit
          ).amount,
          discount: discountAmount
            ? Dinero({
                amount: discountAmount.amount,
                currency: currentStore?.currencyUnit
              }).toUnit()
            : 0
        })
      ]
    }
  })
}

export const subscribeToNewsletter = ({ email, pageLocation }) => {
  const dataLayer = dispatcher(window.dataLayer)
  dataLayer.push({
    event: "newsletter_signup",
    user_id: sha256(email),
    page_location: pageLocation === "/" ? "homepage" : pageLocation
  })
}
