import React, { createContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useBrinkStoreConfig } from "./utils/useBrinkStoreConfig"
import BrinkApi from "./utils/BrinkApi"
import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"
import { navigate } from "gatsby"
import { useCookies } from "react-cookie"
import * as events from "./utils/events"
import { BRINK_CART_EXPIRY_HOURS } from "../../constants"

export const BrinkContext = createContext()
const BRINK_CART_LOCAL_STORAGE_KEY = "BrinkCart"
const BRINK_SHIPPING_ADDRESS_LOCAL_STORAGE_KEY = "BrinkShippingAddress"
const BRINK_BILLING_ADDRESS_LOCAL_STORAGE_KEY = "BrinkBillingAddress"
const BRINK_USE_DIFFERENT_SHIPPING_ADDRESS_LOCAL_STORAGE_KEY =
  "BrinkUseDifferentShippingAddress"
const BRINK_COMMERCE_SESSION_COOKIE = "brinkcommerceSessionId"
const SHOPPER_REFERENCE_COOKIE = "shopperReference"
const SHOPPER_STORE_COOKIE = "shopperStore"
const CART_CLOSED = "Closed"

export const BrinkContextProvider = ({ children, allSanityProducts }) => {
  const BRINKCOMMERCE_API_URL = process.env.GATSBY_BRINKCOMMERCE_API_URL
  const ADYEN_ENVIRONMENT = process.env.GATSBY_ADYEN_ENVIRONMENT
  const ADYEN_CLIENT_KEY = process.env.GATSBY_ADYEN_CLIENT_KEY
  const ENABLED_COUNTRIES = process.env.GATSBY_ENABLED_COUNTRIES
    ? process.env.GATSBY_ENABLED_COUNTRIES.split(",")
    : []
  const DEFAULT_COUNTRY = process.env.GATSBY_DEFAULT_COUNTRY

  const [cookies, _setCookie] = useCookies([
    BRINK_COMMERCE_SESSION_COOKIE,
    SHOPPER_REFERENCE_COOKIE,
    SHOPPER_STORE_COOKIE
  ])

  const [cookieSettingsVisible, setCookieSettingsVisible] = useState(false)

  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])

  const storeOptions = useBrinkStoreConfig()
  const { i18n } = useTranslation()

  const isBrowser = typeof window !== "undefined"

  const setCookie = (name, value, days) => {
    isBrowser &&
      _setCookie(name, value, {
        path: "/",
        expires: dayjs().add(days, "days").toDate()
      })
  }

  const [stores] = useState(() => {
    const stores = storeOptions
      .filter((c) =>
        ENABLED_COUNTRIES.length
          ? ENABLED_COUNTRIES.includes(c.countryCode)
          : true
      )
      .map((it) => ({
        currencyUnit: it.currencyUnit,
        countryCode: it.countryCode,
        languageCode: it.languageCode,
        taxPercentage: it.tax
      }))
      .sort((a, b) => a.countryCode.localeCompare(b.countryCode))

    return stores
      .filter((store) => store.countryCode !== DEFAULT_COUNTRY)
      .concat(stores.filter((store) => store.countryCode === DEFAULT_COUNTRY))
  })

  useMountEffect(() => {
    const fetchAndSetStore = async (stores, brinkApi) => {
      const countryCode = cookies[SHOPPER_STORE_COOKIE]
        ? cookies[SHOPPER_STORE_COOKIE]
        : (await brinkApi.getCountryCode()).countryCode
      const store =
        stores.find((store) => store.countryCode === countryCode) ||
        stores.find((store) => store.countryCode === DEFAULT_COUNTRY)
      setCurrentStore(store)
    }

    const closeExpiredCart = async (brinkApi) => {
      const headers = { Authorization: brinkSessionId }
      await brinkApi
        .getCartFromSession(headers)
        .then(({ data: cartResponse }) => {
          const expiryDateTime = dayjs(cartResponse.expiryDateTime)
            .add(BRINK_CART_EXPIRY_HOURS, "hours")
            .subtract(15, "minutes")
          if (
            cartResponse.state === "CLOSED" ||
            expiryDateTime.isBefore(dayjs())
          ) {
            console.log("cart is expired, closing")
            closeCart()
          }
        })
        .catch(() => console.log("failed to get cart"))
    }

    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    fetchAndSetStore(stores, brinkApi)
    isCartExpired() && closeCart()
    hasSessionId() && closeExpiredCart(brinkApi)
  })

  const [currentStore, _setCurrentStore] = useState(() => {
    const countryCode = cookies[SHOPPER_STORE_COOKIE]
      ? cookies[SHOPPER_STORE_COOKIE]
      : DEFAULT_COUNTRY
    return stores.find((store) => store.countryCode === countryCode)
  })

  const shouldUpdateStore = (store, cart) =>
    cart && cart.store && cart.store.countryCode !== store.countryCode

  const setCurrentStore = (store) => {
    setShippingAddress({ country: store.countryCode })
    setBillingAddress({ country: store.countryCode })
    setCookie(SHOPPER_STORE_COOKIE, store.countryCode, 365)
    if (shouldUpdateStore(store, cart)) {
      updateCartStore(store)
    }
    _setCurrentStore(store)
  }

  const setShippingMethod = (shippingOption) => {
    _setShippingMethod({
      id: shippingOption.id,
      label:
        shippingOption.displayName[languageCode] ||
        shippingOption.displayName.en,
      price: shippingOption.price[currentStore.currencyUnit],
      icon: shippingOption.mainImage.asset.url
    })
    updateProductVariantInCart({
      productVariantId: shippingOption.id,
      quantity: 1,
      shipping: true
    })
  }

  const [countryWhiteList] = useState(stores.map((s) => s.countryCode))
  const [brinkSessionId, _setBrinkSessionId] = useState(
    () => cookies[BRINK_COMMERCE_SESSION_COOKIE]
  )

  const setBrinkSessionId = (sessionId) => {
    setCookie(BRINK_COMMERCE_SESSION_COOKIE, sessionId, 31)
    _setBrinkSessionId(sessionId)
  }

  const languageCode = storeOptions.find(
    (s) => s.languageCode === i18n.language.slice(0, 2)
  )
    ? i18n.language
    : process.env.GATSBY_BUILD_LANGUAGE || "en"

  const [supportedLanguages] = useState(() => ["en"])

  const [shopperReference] = useState(() => {
    if (!cookies[SHOPPER_REFERENCE_COOKIE]) {
      setCookie(SHOPPER_REFERENCE_COOKIE, uuidv4(), 365)
    }
    return cookies[SHOPPER_REFERENCE_COOKIE]
  })

  const [notification, setNotification] = useState({
    [NotificationTypes.CART]: {
      event: null,
      severity: null,
      message: null,
      processing: null
    },
    [NotificationTypes.CHECKOUT]: {
      event: null,
      severity: null,
      message: null,
      processing: null
    }
  })

  const [billingAddress, _setBillingAddress] = useState(() => {
    const localstorageBillingAddress = isBrowser
      ? localStorage.getItem(BRINK_BILLING_ADDRESS_LOCAL_STORAGE_KEY)
      : null
    return localstorageBillingAddress
      ? JSON.parse(decodeUnicode(localstorageBillingAddress))
      : {}
  })
  const [shippingAddress, _setShippingAddress] = useState(() => {
    const localstorageShippingAddress = isBrowser
      ? localStorage.getItem(BRINK_SHIPPING_ADDRESS_LOCAL_STORAGE_KEY)
      : null
    return localstorageShippingAddress
      ? JSON.parse(decodeUnicode(localstorageShippingAddress))
      : {}
  })
  const [useDifferentShippingAddress, _setUseDifferentShippingAddress] =
    useState(() => {
      const localstorageUseDifferentShippingAddress = isBrowser
        ? localStorage.getItem(
            BRINK_USE_DIFFERENT_SHIPPING_ADDRESS_LOCAL_STORAGE_KEY
          )
        : null

      return localstorageUseDifferentShippingAddress
        ? /true/i.test(localstorageUseDifferentShippingAddress)
        : false
    })
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingAddToCart, setLoadingAddToCart] = useState(false)
  const [order, setOrder] = useState({})
  const [discountCode, setDiscountCode] = useState(null)
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false)
  const [cart, _setcart] = useState(() => {
    const localstorageCart = isBrowser
      ? localStorage.getItem(BRINK_CART_LOCAL_STORAGE_KEY)
      : null
    const cart = localstorageCart
      ? JSON.parse(decodeUnicode(localstorageCart))
      : {
          cartItems: [],
          totalPrice: 0,
          totalPriceWithDiscount: 0,
          totalDiscountAmount: 0,
          discounts: {}
        }
    const rules =
      cart.discounts &&
      cart.discounts.rules &&
      cart.discounts.rules.filter((r) => r.ruleType === "DISCOUNTCODE")
    const discountCode =
      rules && rules.length > 0 ? rules[0].ruleData.discountCode : null
    setDiscountCode(discountCode)
    return cart
  })

  const setShippingAddress = (inShippingAddress) => {
    _setShippingAddress({ ...shippingAddress, ...inShippingAddress })
    if (isBrowser) {
      localStorage.setItem(
        BRINK_SHIPPING_ADDRESS_LOCAL_STORAGE_KEY,
        encodeUnicode(
          JSON.stringify({ ...shippingAddress, ...inShippingAddress })
        )
      )
    }
  }

  const setUseDifferentShippingAddress = (currentValue) => {
    _setUseDifferentShippingAddress(currentValue)
    if (isBrowser) {
      localStorage.setItem(
        BRINK_USE_DIFFERENT_SHIPPING_ADDRESS_LOCAL_STORAGE_KEY,
        currentValue
      )
    }
  }

  const setBillingAddress = (inBillingAddress) => {
    _setBillingAddress({ ...billingAddress, ...inBillingAddress })
    if (isBrowser) {
      localStorage.setItem(
        BRINK_BILLING_ADDRESS_LOCAL_STORAGE_KEY,
        encodeUnicode(
          JSON.stringify({ ...billingAddress, ...inBillingAddress })
        )
      )
    }
  }

  const [shippingMethod, _setShippingMethod] = useState(() => {
    const shipping =
      cart && cart.cartItems.find((c) => c.type === "shippingOption")
    return shipping
      ? {
          id: shipping.id,
          label:
            shipping.attribute.displayName[languageCode] ||
            shipping.attribute.displayName.en,
          price: shipping.price[currentStore.currencyUnit],
          icon: shipping.imageUrl
        }
      : null
  })

  const setCart = (cart) => {
    _setcart(cart)
    if (isBrowser) {
      localStorage.setItem(
        BRINK_CART_LOCAL_STORAGE_KEY,
        encodeUnicode(JSON.stringify(cart))
      )
    }
    const rules =
      cart.discounts &&
      cart.discounts.rules &&
      cart.discounts.rules.filter((r) => r.ruleType === "DISCOUNTCODE")

    const discountCode =
      rules && rules.length > 0 ? rules[0].ruleData.discountCode : null
    setDiscountCode(discountCode)
  }

  const removeProductFromCart = async (productVariantId) => {
    const p = cart.cartItems.find((p) => p.id === productVariantId)
    const response = await updateProductVariantInCart({
      productVariantId,
      quantity: 0
    })
    events.removeFromCart({
      productVariantId,
      name: p.name,
      quantity: p.quantity,
      category: p.category,
      currentStore,
      price: p.price
    })
  }

  const plusOneProductVariantToCart = async (productVariantId) => {
    const p = cart.cartItems.find((p) => p.id === productVariantId)

    await updateProductVariantInCart({
      productVariantId: p.id,
      quantity: 1
    }).then((result) =>
      events.addToCart({
        cartResponse: result.cartResponse,
        allSanityProducts,
        productVariantId,
        quantity: 1,
        currentStore,
        category: p.category,
        name: p.name
      })
    )
  }

  const minusOneProductVariantToCart = (productVariantId) => {
    const p = cart.cartItems.find((p) => p.id === productVariantId)

    updateProductVariantInCart({
      productVariantId: p.id,
      quantity: -1
    })
    events.removeFromCart({
      productVariantId,
      name: p.name,
      quantity: 1,
      category: p.category,
      price: p.price,
      discount: p?.discount && 0,
      currentStore
    })
  }

  const addProductVariantsToCart = async (
    productVariantId,
    quantity,
    openCart = false
  ) => {
    const cartResponse = await updateProductVariantInCart({
      productVariantId: productVariantId,
      quantity: quantity,
      openCart: openCart
    })
    setLoadingAddToCart(false)
    const p = cartResponse.cartItems.find((p) => p.id === productVariantId)
    events.addToCart({
      cartResponse,
      allSanityProducts,
      productVariantId,
      quantity,
      currentStore,
      category: p.category,
      name: p.name
    })
    setIsCartOpen(true)
  }

  const removeProductVariantsFromCart = (productVariantId, quantity) => {
    updateProductVariantInCart({
      productVariantId: productVariantId,
      quantity: quantity
    })
  }

  const removeDiscountFromCart = async () => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    try {
      const responseData = await brinkApi.removeDiscount({
        headers: { Authorization: brinkSessionId },
        products: [{ id: shippingMethod.id, quantity: 1 }]
      })
      setBrinkSessionId(responseData.jwtToken)
      setCart(responseData.cart)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
    }
  }

  const updateProductVariantInCart = async ({
    productVariantId,
    quantity,
    shipping = false
  }) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    const products = getProducts({ productVariantId, quantity }, shipping)
    let responseData
    try {
      if (isCartClosed()) {
        localStorage.removeItem(BRINK_CART_LOCAL_STORAGE_KEY)
        responseData = await brinkApi.syncCart({
          action: "post",
          products: products,
          currencyUnit: currentStore.currencyUnit,
          languageCode: languageCode,
          countryCode: currentStore.countryCode,
          taxPercentage: currentStore.taxPercentage
        })
      } else {
        responseData = await brinkApi.syncCart({
          headers: { Authorization: brinkSessionId },
          action: "put",
          products: products
        })
      }
      if (responseData.error) {
        setIsLoading(false)
        setIsCartOpen(false)
        if (responseData.error === "Cart closed") {
          closeCart()
        }

        const navigation = {
          "Out of stock": "/error-out-of-stock/",
          "Out of stock with reservations": "/error-out-of-stock/"
        }
        navigate(navigation[responseData.error] || "/error/", {
          state: {
            error: responseData.error
          }
        })
        return
      }
      setBrinkSessionId(responseData.jwtToken)
      if (responseData.cart) {
        if (
          responseData.cart.cartItems.filter(
            (item) => item.type !== "shippingOption"
          ).length === 0
        ) {
          setIsCartOpen(false)
          closeCart()
        } else {
          setCart(responseData.cart)
        }
      }
      setIsLoading(false)
      return responseData.cart
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
      return null
    }
  }

  const updateCartStore = async (store) => {
    if (!isCartClosed()) {
      const brinkApi = new BrinkApi({
        url: BRINKCOMMERCE_API_URL,
        setNotification: setNotification
      })

      const responseData = await brinkApi.updateCartStore({
        headers: { Authorization: brinkSessionId },
        countryCode: store.countryCode,
        languageCode: store.languageCode
      })
      if (responseData.error) {
        const navigation = {
          "Out of stock": "/error-out-of-stock/",
          "Out of stock with reservations": "/error-out-of-stock/"
        }
        navigate(navigation[responseData.error] || "/error/", {
          state: {
            error: responseData.error
          }
        })

        return
      }
      setCart(responseData.cart)
    }
  }

  const getProducts = ({ productVariantId, quantity }, shipping) => {
    const products = [{ id: productVariantId, quantity }]
    return !shipping && shippingMethod
      ? [...products, { id: shippingMethod.id, quantity: 1 }]
      : products
  }

  const closeCart = (clearShippingMethod = false) => {
    const closedCart = {
      ...cart,
      state: CART_CLOSED,
      cartItems: []
    }
    setCart(closedCart)
    clearShippingMethod && _setShippingMethod(null)
  }

  const isCartClosed = () => cart.state === CART_CLOSED || !hasSessionId()

  const isCartExpired = () => isCartClosed() && !hasSessionId()

  const hasSessionId = () => (brinkSessionId || "").split(".").length === 3

  const addDiscount = async ({ code }) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const responseData = await brinkApi.addDiscount({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        code: code,
        products: [{ id: shippingMethod.id, quantity: 1 }]
      })
      setIsLoading(false)
      if (responseData) {
        setCart(responseData.cart)
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
    }
  }

  const makePayment = async (
    order,
    paymentMethod,
    storePaymentMethod,
    browserInfo
  ) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      return await brinkApi.makePayment({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        orderId: order.id,
        paymentMethod: paymentMethod,
        storePaymentMethod: storePaymentMethod,
        shopperReference: shopperReference,
        browserInfo: browserInfo,
        redirect: getRedirect()
      })
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const getRedirect = () => {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }`
    return {
      success: `${baseUrl}/success/`,
      error: `${baseUrl}/checkout/`,
      canceled: `${baseUrl}/checkout/`,
      default: `${baseUrl}/`
    }
  }

  const getPaymentMethods = async () => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      return await brinkApi.getPaymentMethods({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        currencyUnit: currentStore.currencyUnit,
        countryCode: shippingAddress.country,
        blockedPaymentMethods: ["swish"],
        amount: cart.totalPriceWithDiscount,
        shopperReference: shopperReference
      })
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const searchProducts = async (searchQuery) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const products = (
        await brinkApi.searchProducts({ query: searchQuery })
      ).products.filter(
        (product) =>
          product.type === "product" || product.type === "productVariant"
      )
      setIsLoading(false)
      return products
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
    }
  }

  const makeDetailsCall = async (orderId, details) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      return await brinkApi.makeDetailsCall({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        orderId: orderId,
        details: details
      })
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const cartToOrder = async () => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const responseData = await brinkApi.cartToOrder({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        email: billingAddress.email,
        shippingAddress: toOrderShippingAddress(),
        billingAddress: toOrderBillingAddress()
      })
      if (responseData.error) {
        if (
          responseData.error === "Cart closed" ||
          responseData.error === "Order is already successful"
        ) {
          closeCart()
        }

        const navigation = {
          "Out of stock": "/error-out-of-stock/",
          "Order is already successful": "/"
        }
        navigate(navigation[responseData.error] || "/error/", {
          state: {
            error: responseData.error
          }
        })
        return null
      }
      return responseData
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const getInstagramLatestImages = async () => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    return await brinkApi.getInstagramLatestImages()
  }

  const getShippingOptions = async (countryCode) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      return await brinkApi.getShippingOptions({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        countryCode: countryCode
      })
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const getSetCart = async (sessionId, token) => {
    setBrinkSessionId(token)
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    try {
      const cart = await brinkApi.getCart(sessionId)
      const shipping = cart.cartItems.find((c) => c.type === "shippingOption")
      _setShippingMethod({
        id: shipping.id,
        label:
          shipping.attribute.displayName[languageCode] ||
          shipping.attribute.displayName.en,
        price: shipping.price[currentStore.currencyUnit],
        icon: shipping.imageUrl
      })
      setCart(cart)
      return cart
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
      return null
    }
  }

  const getStocks = async (productIds) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    const splitEvery = (n, xs, y = []) => {
      return xs.length === 0
        ? y
        : splitEvery(n, xs.slice(n), y.concat([xs.slice(0, n)]))
    }

    const flatSortedProductIds = Array.from(productIds).flat().sort()

    try {
      return await Promise.all(
        splitEvery(500, flatSortedProductIds).map((batch) => {
          return brinkApi.getStocks(batch)
        })
      )
    } catch (error) {
      setIsLoading(false)
      return []
    }
  }

  const getOrderConfirmation = async (orderId, signature) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      setIsLoading(true)
      const orderConfirmation = await brinkApi.getOrderConfirmation(
        orderId,
        signature
      )
      setIsLoading(false)
      return orderConfirmation
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
      return null
    }
  }

  const toOrderShippingAddress = () => ({
    givenName: shippingAddress.firstName,
    familyName: shippingAddress.lastName,
    phone: shippingAddress.phone,
    streetAddress: shippingAddress.address,
    houseNumberOrName: shippingAddress.houseNumberOrName,
    postalCode: shippingAddress.postalCode,
    city: shippingAddress.city,
    region: shippingAddress.region,
    country: shippingAddress.country
  })

  const toOrderBillingAddress = () => ({
    givenName: billingAddress.firstName,
    familyName: billingAddress.lastName,
    phone: billingAddress.phone,
    streetAddress: billingAddress.address,
    houseNumberOrName: billingAddress.houseNumberOrName,
    postalCode: billingAddress.postalCode,
    city: billingAddress.city,
    region: billingAddress.region,
    country: billingAddress.country
  })

  const contextObjects = {
    ADYEN_ENVIRONMENT,
    ADYEN_CLIENT_KEY,
    allSanityProducts,
    makePayment,
    cartToOrder,
    getPaymentMethods,
    addDiscount,
    removeProductFromCart,
    plusOneProductVariantToCart,
    minusOneProductVariantToCart,
    addProductVariantsToCart,
    removeProductVariantsFromCart,
    removeDiscountFromCart,
    discountCode,
    cart,
    setNotification,
    notification,
    languageCode,
    isLoading,
    setIsLoading,
    isCartOpen,
    setIsCartOpen,
    billingAddress,
    setBillingAddress,
    shippingAddress,
    setShippingAddress,
    useDifferentShippingAddress,
    setUseDifferentShippingAddress,
    shippingMethod,
    order,
    setOrder,
    closeCart,
    isCartClosed,
    isCartExpired,
    makeDetailsCall,
    stores,
    supportedLanguages,
    searchProducts,
    setCurrentStore,
    currentStore,
    setShippingMethod,
    setShowNewsletterPopup,
    showNewsletterPopup,
    getSetCart,
    brinkSessionId,
    getShippingOptions,
    getInstagramLatestImages,
    getStocks,
    cookieSettingsVisible,
    setCookieSettingsVisible,
    countryWhiteList,
    getOrderConfirmation,
    loadingAddToCart,
    setLoadingAddToCart
  }

  return (
    <BrinkContext.Provider value={contextObjects}>
      {children}
    </BrinkContext.Provider>
  )
}

export const NotificationTypes = {
  CART: "CART",
  CHECKOUT: "CHECKOUT"
}

export const NotificationSeverityLevel = {
  ERROR: "ERROR",
  INFO: "INFO"
}

export const NotificationEvent = {
  UPDATE_CART: "UPDATE_CART",
  CREATE_PAYMENT_CHECKOUT: "CREATE_PAYMENT_CHECKOUT",
  PROCESSING_PAYMENT: "PROCESSING_PAYMENT",
  APPLYING_DISCOUNT_CODE: "APPLYING_DISCOUNT_CODE",
  REMOVE_DISCOUNT_CODE: "REMOVE_DISCOUNT_CODE",
  PROCESSING_CONFIRMATION: "PROCESSING_CONFIRMATION"
}

const encodeUnicode = (str) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode("0x" + p1)
    )
  )

const decodeUnicode = (str) =>
  decodeURIComponent(
    atob(str)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  )
