import React from "react"
import { I18nextProvider } from "react-i18next"
import { i18nextConfig } from "./src/i18n/locales/config"
import { CookiesProvider } from "react-cookie"
import Amplify from "aws-amplify"
import { BrinkContextProvider } from "./src/components/context/BrinkContext"
import "lazysizes"
import "./src/styles/global.css"

Amplify.configure({
  Auth: {
    region: process.env.GATSBY_AWS_REGION,
    userPoolId: process.env.GATSBY_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
    cookieStorage: {
      domain: process.env.GATSBY_SITE_DOMAIN || "localhost",
      secure: true
    }
  }
})

export const wrapPageElement = ({ element, props }) => {
  const { sanityTranslations, allSanityProducts } = props.pageContext
  return (
    <I18nextProvider i18n={i18nextConfig(sanityTranslations)}>
      <CookiesProvider>
        <BrinkContextProvider allSanityProducts={allSanityProducts}>
          {element}
        </BrinkContextProvider>
      </CookiesProvider>
    </I18nextProvider>
  )
}

export const shouldUpdateScroll = ({ routerProps }) =>
  routerProps.location.state && routerProps.location.state.disableScrollUpdate
    ? false
    : true

// GTAG disabled default in gatsby gtag plugin.
//if (process.env.NODE_ENV === "development") window.gtag = () => { }

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "virtual_page_view",
      page: location.pathname,
    })
  }
}

let nextRoute = ``
export const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname
}

window.addEventListener("unhandledrejection", (event) => {
  if (/Loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute
    }
  }
})
