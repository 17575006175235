export const boxShadow = "0 2px 8px 0 rgba(0, 0, 0, 0.2)"
export const containerSmallMaxWidth = "120rem"
export const containerMaxWidth = "150rem"
export const imageFilter = "brightness(90%) contrast(125%)"

export const TINY = 320
export const SMALL = 560
export const MEDIUM = 1024
export const LARGE = 1440
export const X_LARGE = 1550
export const MEDIA_TINY = `@media (max-width: ${TINY}px)`
export const MEDIA_MAX_SMALL = `@media (max-width: ${SMALL}px)`
export const MEDIA_SMALL = `@media (min-width: ${
  TINY + 1
}px) and (max-width: ${SMALL}px)`
export const MEDIA_MIN_MEDIUM = `@media (min-width: ${SMALL + 1}px)`
export const MEDIA_MAX_MEDIUM = `@media (max-width: ${MEDIUM}px)`
export const MEDIA_MEDIUM = `@media (min-width: ${
  SMALL + 1
}px) and (max-width: ${MEDIUM}px)`
export const MEDIA_MIN_LARGE = `@media (min-width: ${MEDIUM + 1}px)`
export const MEDIA_MIN_X_LARGE = `@media (min-width: ${LARGE + 1}px)`
export const MEDIA_MIN_XX_LARGE = `@media (min-width: ${X_LARGE + 1}px)`
// Time duration in hours items remain in cart to override 15 mins Brink Cart Expiry
export const BRINK_CART_EXPIRY_HOURS = 72;
